body {
  font-family: "Roboto",sans-serif;
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  visibility: hidden;
}

input[type=number] {
  -moz-appearance: textfield;
}

.table {
  font-size: 14px;
}

.table.table-bordered {
  border: none;
}

.table thead th {
  background: #fff;
  position: relative;
  padding: 6px;
  overflow-wrap: break-word;
  font-weight: 500;
}

.table tbody th {
  font-weight: 400;
}

.table thead th:not(.with-btn),
.table thead td:not(.with-btn),
.table tbody th:not(.with-btn),
.table tbody td:not(.with-btn) {
  min-width: 90px;
  max-width: 120px;
  width: auto;
  vertical-align: middle;
  line-height: 1;
}

.table tbody tr:hover {
  background: rgba(0,0,0,.075) !important;
  color: #212529;
}

.table tbody th,
.table tbody td {
  padding: 4px;
  word-break: break-word;
}

.table th,
.table td {
  vertical-align: middle;
}

.table .fitwidth {
  width: 1px;
}

.table .stretch {
  width: 100%;
}

.table .main-area {
  background: rgba(0,0,0,.05);
}

.container {
  max-width: 1262px !important;
}

.h2 {
  font-weight: 700;
}

.fz10 {
  font-size: 10px;
}

.fz12 {
  font-size: 12px;
}

.fz14 {
  font-size: 14px;
}

.fz16 {
  font-size: 16px;
}

.fz18 {
  font-size: 18px;
}

.fw500 {
  font-weight: 500;
}

.text-blue {
  color: #182b53 !important;
}

.text-black {
  color: #2f2f2f !important;
}

.text-gray {
  color: #5c5c5c !important;
}

.border-blue {
  border-color: #182b53 !important;
}

.bg-primary {
  background: #182b53 !important;
}

.bg-danger {
  background: #e74c3c !important;
}

.bg-light-blue {
  background: #c0e5ff !important;
}

.w-25 {
  width: 25%;
}

.custom-select {
  background: #fff url("/img/select.svg") right .75rem center/8px 10px no-repeat;
}

.custom-select.fixed-width {
  max-width: 212px;
  display: block;
}

.mb-32,
.my-32,
.m-32 {
  margin-bottom: 2rem;
}

.mt-32,
.my-32,
.m-32 {
  margin-top: 2rem;
}

.ml-32,
.mx-32,
.m-32 {
  margin-left: 2rem;
}

.mr-32,
.mx-32,
.m-32 {
  margin-right: 2rem;
}

.pb-32,
.py-32,
.p-32 {
  padding-bottom: 2rem;
}

.pt-32,
.py-32,
.p-32 {
  padding-top: 2rem;
}

.pl-32,
.px-32,
.p-32 {
  padding-left: 2rem;
}

.pr-32,
.px-32,
.p-32 {
  padding-right: 2rem;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  border: none;
  box-shadow: 4px 4px 15px rgba(0,0,0,.25);
}

.btn.btn-primary,
.btn.btn-secondary {
  background: #182b53 !important;
  color: #fff !important;
}

.btn.btn-primary:hover:not(:disabled):not(.disabled),
.btn.btn-secondary:hover:not(:disabled):not(.disabled) {
  background: #fff !important;
  color: #182b53 !important;
}

.btn.btn-primary-outline {
  background: #fff !important;
  color: #182b53 !important;
}

.btn.btn-primary-outline:hover:not(:disabled):not(.disabled) {
  background: #182b53 !important;
  color: #fff !important;
}

.btn.btn-primary-outline:focus:not(:disabled):not(.disabled) {
  box-shadow: none !important;
}

.btn.btn-secondary {
  border-radius: 0;
  box-shadow: none;
  justify-content: start;
  text-align: left;
}

.btn.btn-secondary:hover {
  box-shadow: 4px 4px 15px rgba(0,0,0,.25);
}

.btn.btn-auth {
  color: #182b53 !important;
  padding: 10px !important;
  border: 1px solid #fff !important;
  border-bottom-color: currentColor !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  transition: all 5ms;
}

.btn.btn-auth:hover {
  padding: 10px 16px !important;
  border-color: currentColor !important;
  border-radius: 10px !important;
  margin-right: -6px;
  text-decoration: none;
  animation: .05;
}

.btn.btn-flat {
  padding: 0 !important;
  background: none;
  border: none;
  line-height: 0;
  box-shadow: none;
}

.btn.btn-flat:focus:not(:disabled):not(.disabled) {
  box-shadow: none;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  color: #182b53;
  text-decoration: none;
  border-bottom: 1px solid rgba(0,0,0,0);
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  border-color: currentColor;
}

.btn-link.active {
  border-bottom: 1px solid;
}

.btn-link.with-arrow {
  display: inline-flex;
  align-items: center;
  border: none;
}

.btn-link.with-arrow::after {
  content: "";
  margin-left: .25rem;
  width: 18px;
  height: 18px;
  background: url("/img/arrow.svg") no-repeat right center/contain;
}

.btn.table-btn {
  width: 30px;
  height: 24px;
  padding: 1px 2px;
  border: 1px solid #3498db;
  background: none;
  line-height: 1;
  box-shadow: 5px 5px 5px rgba(0,0,0,.15);
}

.btn.table-btn.danger {
  border-color: #e74c3c;
  color: #e74c3c;
}

.btn.table-btn.primary {
  border-color: #62cb31;
  color: #62cb31;
}

.btn.table-btn img {
  vertical-align: baseline;
}

.btn-text.hide,
.btn-icon.hide {
  position: absolute;
  width: 0 !important;
  padding: 0 !important;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  opacity: 0;
  border: none;
}

.btn-icon {
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #3b97d1;
  border-radius: 8px;
}

.btn-icon.border-0 {
  padding: 2px;
  border: none;
}

.btn:hover .btn-icon {
  background: #c0e5ff !important;
  border-color: #c0e5ff !important;
}

.btn-text {
  transition: all 200ms ease-in-out;
}

.icon {
  width: 40px;
  height: 40px;
}

.icon-sm {
  width: 25px;
  height: 25px;
}

.icon-acab {
  background: url("/img/menu/acab.svg") no-repeat center/contain;
}

.icon-accrual {
  background: url("/img/menu/accrual.svg") no-repeat center/contain;
}

.icon-administration {
  background: url("/img/menu/administration.svg") no-repeat center/contain;
}

.icon-ads {
  background: url("/img/menu/ads.svg") no-repeat center/contain;
}

.icon-analytics {
  background: url("/img/menu/analytics.svg") no-repeat center/contain;
}

.icon-certificate {
  background: url("/img/menu/certificate.svg") no-repeat center/contain;
}

.icon-contacts {
  background: url("/img/menu/contacts.svg") no-repeat center/contain;
}

.icon-control-room {
  background: url("/img/menu/control-room.svg") no-repeat center/contain;
}

.icon-documents {
  background: url("/img/menu/documents.svg") no-repeat center/contain;
}

.icon-information {
  background: url("/img/menu/information.svg") no-repeat center/contain;
}

.icon-menu {
  background: url("/img/menu/menu.svg") no-repeat center/contain;
}

.icon-meters {
  background: url("/img/menu/meters.svg") no-repeat center/contain;
}

.icon-news {
  background: url("/img/menu/news.svg") no-repeat center/contain;
}

.icon-passport-office {
  background: url("/img/menu/passport-office.svg") no-repeat center/contain;
}

.icon-photos {
  background: url("/img/menu/photos.svg") no-repeat center/contain;
}

.icon-poll {
  background: url("/img/menu/poll.svg") no-repeat center/contain;
}

.icon-reports {
  background: url("/img/menu/reports.svg") no-repeat center/contain;
}

.icon-services {
  background: url("/img/menu/services.svg") no-repeat center/contain;
}

.icon-settings {
  background: url("/img/menu/settings.svg") no-repeat center/contain;
}

.icon-site-settings {
  background: url("/img/menu/site-settings.svg") no-repeat center/contain;
}

.icon-exit {
  background: url("/img/menu/exit.svg") no-repeat center/contain;
}

.icon-exit-invert {
  background: url("/img/menu/exit-invert.svg");
}

.icon-client {
  background: url("/img/main.svg") no-repeat center/contain;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox-label {
  margin-bottom: 0;
  line-height: 1;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox-mark {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.custom-checkbox-mark::before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 4px;
  background: url("/img/checkbox.svg") no-repeat center/contain;
}

.custom-checkbox-mark::after {
  content: "";
  position: absolute;
  top: -10%;
  left: 10%;
  width: 100%;
  height: 100%;
}

.custom-checkbox-input:checked~.custom-checkbox-mark::after {
  background: url("/img/check-solid.svg") no-repeat center/contain;
}

.custom-checkbox-sm .custom-checkbox-mark {
  width: 15px;
  height: 15px;
}

.custom-checkbox-lg .custom-checkbox-mark {
  width: 40px;
  height: 40px;
}

.shadow {
  box-shadow: 5px 5px 5px rgba(0,0,0,.15) !important;
}

.text-secondary {
  color: #5c5c5c !important;
}

.top-0 {
  top: 0;
}

.top-100 {
  top: 100%;
}

.right-0 {
  right: 0;
}

.left-100 {
  left: 100%;
}

.z-index-100 {
  z-index: 100;
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.result-block.fade:not(.show) {
  z-index: -1;
}

.multiselect-container {
  width: 100%;
}

.dropdown-menu {
  border-top: 4px solid rgba(0,0,0,0);
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background: #182b53;
  color: #fff !important;
}

.form-group.inline {
  margin-left: .5rem;
  margin-right: .5rem;
}

.form-group.inline:first-child {
  margin-left: 0;
}

.form-group.inline:last-child {
  margin-right: 0;
}

.send-form {
  margin-right: 190px;
}

.send-form-wrap {
  border: 1px solid #c9c9c9;
  border-radius: 10px;
}

.form-sender {
  position: relative;
}

.form-sender .tooltip-wrap,
.form-sender::after {
  position: absolute;
  content: "";
  right: 10px;
  top: 50%;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
}

.form-sender.success::after {
  background: url("/img/check-solid.svg") no-repeat center/contain;
}

.form-sender.error::after {
  background: url("/img/circle-xmark-solid.svg") no-repeat center/contain;
}

.form-sender.sending::after {
  background: url("/img/spinner-solid.svg") no-repeat center/contain;
  animation: loader 1.2s linear infinite;
}

.form-sender .form-control {
  padding-right: 40px;
}

.form-sender .tooltip-wrap {
  z-index: 2;
}

.navbar-side {
  width: 180px;
}

.navbar-menu {
  font-size: 18px;
}

.side-menu {
  flex: 0 1 0;
}

.acabName {
  max-width: 360px;
}

.main-table {
  flex: 1 0 0;
  min-width: 0;
}

.search {
  position: relative;
  padding: 13px 8px;
  font-size: 14px;
  line-height: 16px;
  background: #fff;
  border: none;
  border-radius: 8px;
}

.search-wrap {
  box-shadow: 3px 4px 9px rgba(0,0,0,.25);
  border-radius: 8px;
}

.search-icon {
  background: url("/img/search.svg") no-repeat center/contain;
  width: 20px;
}

.single-iban .form-control {
  margin-right: .5rem;
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.result-block.fade:not(.show) {
  z-index: -1;
}

.login-form {
  max-width: 454px;
}

.restore-form {
  max-width: 480px;
}

.footer {
  background: #182b53;
  color: #fff;
}

@media (max-width: 768px) {
  h2,
  .h2,
  h3,
  .h3 {
    font-size: 1.25rem;
  }

  .text-xs-white {
    color: #fff !important;
  }

  .border-right {
    border: none !important;
  }

  .dropdown-close {
    position: absolute;
    color: #fff;
    right: 20px;
    top: 16px;
  }

  .dropdown-toggle::after {
    content: none;
  }

  .dropdown-menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    border: none;
    border-radius: 0;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .navbar-side {
    width: auto;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .shadow {
    box-shadow: none !important;
  }

  .btn {
    font-size: 12px;
  }

  .btn.btn-auth {
    color: #fff !important;
    border: none !important;
    border-bottom: 1px solid currentColor !important;
  }

  .userMenu {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
  }

  .userMenu .close {
    position: absolute;
    right: 20px;
    top: 16px;
    z-index: 1050;
  }

  .acabName {
    max-width: 59vw;
    width: 59vw;
  }

  .roleName {
    max-width: 41vw;
    width: 41vw;
  }

  .contacts {
    border: 1px solid #182b53;
    border-radius: 8px;
  }

  .footer {
    background-color: #fff;
    color: #2f2f2f;
  }

  .footer .w-25 {
    width: 100% !important;
  }

  .user-change-form {
    border: none !important;
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
}

.loader::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
}

.loader::after {
  z-index: 2;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #000 rgba(0,0,0,0) #000 rgba(0,0,0,0);
  animation: loader 1.2s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.inactive {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none;
}

.tab-active {
  font-size: 18px;
  font-weight: 500;
  color: #000 !important;
}

.remove-variant {
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.muted-block {
  opacity: .4;
  filter: grayscale(1);
  transition: all .2s ease;
}

.news img {
  max-width: 100%;
  max-height: 100%;
}

.content img,
.public-ad img {
  width: auto !important;
  height: auto !important;
  max-width: 100%;
}

.content table {
  max-width: 100%;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}