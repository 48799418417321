$imgPath: '/img/';

body {
  font-family: 'Roboto', sans-serif;
}
input:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  visibility: hidden;
}
input[type=number] {
  -moz-appearance: textfield;
}

.table {
  font-size: 14px;
  &.table-bordered {
    border: none;
  }
  thead th {
    position: relative;
    padding: 6px;
    overflow-wrap: break-word;
    font-weight: 500;
    background-color: #fff;
  }
  tbody th {
    font-weight: 400;
  }
  thead, tbody {
    th, td {
      &:not(.with-btn) {
        min-width: 90px;
        max-width: 120px;
        width: auto;
        vertical-align: middle;
        line-height: 1;
      }
    }
  }
  tbody {
    tr:hover {
      color: #212529;
      background-color: rgba(0,0,0,.075) !important;
    }
    th, td {
      padding: 4px;
      word-break: break-word;
    }
  }
  th, td {
    vertical-align: middle;
  }
  .fitwidth {
    width: 1px;
  }
  .stretch {
    width: 100%;
  }
  .main-area {
    background-color: rgba(0,0,0,.05);
  }
}

.container {
  max-width: 1262px !important;
}

.h2 {
  font-weight: 700;
}

.fz10 {
  font-size: 10px;
}

.fz12 {
  font-size: 12px;
}

.fz14 {
  font-size: 14px;
}

.fz16 {
  font-size: 16px;
}

.fz18 {
  font-size: 18px;
}

.fw500 {
  font-weight: 500;
}

.text-blue {
  color: #182B53 !important;
}

.text-black {
  color: #2F2F2F !important;
}

.text-gray {
  color: #5C5C5C !important;
}

.border-blue {
  border-color: #182B53 !important;
}

.bg-primary {
  background-color: #182B53 !important;
}

.bg-danger {
  background-color: #E74C3C !important;
}

.bg-light-blue {
  background: #C0E5FF !important;
}

.w-25 {
  width: 25%;
}

.custom-select {
  background: #fff url($imgPath + 'select.svg') right .75rem center/8px 10px no-repeat;
  &.fixed-width {
    max-width: 212px;
    display: block;
  }
}

.mb-32, .my-32, .m-32 {
  margin-bottom: 2rem;
}
.mt-32, .my-32, .m-32 {
  margin-top: 2rem;
}
.ml-32, .mx-32, .m-32 {
  margin-left: 2rem;
}
.mr-32, .mx-32, .m-32 {
  margin-right: 2rem;
}
.pb-32, .py-32, .p-32 {
  padding-bottom: 2rem;
}
.pt-32, .py-32, .p-32 {
  padding-top: 2rem;
}
.pl-32, .px-32, .p-32 {
  padding-left: 2rem;
}
.pr-32, .px-32, .p-32 {
  padding-right: 2rem;
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 18px;
  border: none;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
  &#{&}-primary {
    background-color: #182B53 !important;
    color: #fff !important;
    &:hover:not(:disabled):not(.disabled) {
      background-color: #fff !important;
      color: #182B53 !important;
    }
  }
  &#{&}-primary-outline {
    background-color: #FFFFFF !important;
    color: #182B53 !important;
    &:hover:not(:disabled):not(.disabled) {
      background-color: #182B53 !important;
      color: #fff !important;
    }
    &:focus:not(:disabled):not(.disabled) {
      box-shadow: none !important;
    }
  }
  &#{&}-secondary {
    @extend .btn-primary;
    border-radius: 0;
    box-shadow: none;
    justify-content: start;
    text-align: left;
    &:hover {
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
    }
  }
  &#{&}-auth {
    color: #182B53 !important;
    padding: 10px !important;
    border: 1px solid #fff !important;
    border-bottom-color: currentColor !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    transition: all 5ms;
    &:hover {
      padding: 10px 16px !important;
      border-color: currentColor !important;
      border-radius: 10px !important;
      margin-right: -6px;
      text-decoration: none;
      animation: .05;
    }
  }
  &#{&}-flat {
    padding: 0 !important;
    background: none;
    border: none;
    line-height: 0;
    box-shadow: none;
    &:focus:not(:disabled):not(.disabled) {
      box-shadow: none;
    }
  }
  &-link {
    color: #182B53;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    &:hover,
    &:active,
    &:focus {
      @extend .btn-link;
      border-color: currentColor;
    }
    &.active {
      border-bottom: 1px solid;
    }
    &.with-arrow {
      display: inline-flex;
      align-items: center;
      border: none;
      &::after {
        content: '';
        margin-left: 0.25rem;
        width: 18px;
        height: 18px;
        background: url($imgPath + 'arrow.svg') no-repeat right center/contain;
      }
    }
  }
  &.table-btn {
    width: 30px;
    height: 24px;
    padding: 1px 2px;
    border: 1px solid #3498DB;
    background: none;
    line-height: 1;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
    &.danger {
      border-color: #E74C3C;
      color: #E74C3C;
    }
    &.primary {
      border-color: #62CB31;
      color: #62CB31;
    }
    img {
      vertical-align: baseline;
    }
  }
  &-text, &-icon {
    &.hide {
      position: absolute;
      width: 0 !important;
      padding: 0 !important;
      overflow: hidden;
      font-size: 0;
      line-height: 0;
      opacity: 0;
      border: none;
    }
  }
  &-icon {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3B97D1;
    border-radius: 8px;
    &.border-0 {
      padding: 2px;
      border: none;
    }
  }
  &:hover &-icon {
    border-color: #C0E5FF !important;
    background-color: #C0E5FF !important;
  }
  &-text {
    transition: all 200ms ease-in-out;
  }
}

.icon {
  $imgMenuIconPath: $imgPath + 'menu/';

  width: 40px;
  height: 40px;
  &-sm {
    width: 25px;
    height: 25px;
  }
  &-acab {
    background: url($imgMenuIconPath + 'acab.svg') no-repeat center/contain;
  }
  &-accrual {
    background: url($imgMenuIconPath + 'accrual.svg') no-repeat center/contain;
  }
  &-administration {
    background: url($imgMenuIconPath + 'administration.svg') no-repeat center/contain;
  }
  &-ads {
    background: url($imgMenuIconPath + 'ads.svg') no-repeat center/contain;
  }
  &-analytics {
    background: url($imgMenuIconPath + 'analytics.svg') no-repeat center/contain;
  }
  &-certificate {
    background: url($imgMenuIconPath + 'certificate.svg') no-repeat center/contain;
  }
  &-contacts {
    background: url($imgMenuIconPath + 'contacts.svg') no-repeat center/contain;
  }
  &-control-room {
    background: url($imgMenuIconPath + 'control-room.svg') no-repeat center/contain;
  }
  &-documents {
    background: url($imgMenuIconPath + 'documents.svg') no-repeat center/contain;
  }
  &-information {
    background: url($imgMenuIconPath + 'information.svg') no-repeat center/contain;
  }
  &-menu {
    background: url($imgMenuIconPath + 'menu.svg') no-repeat center/contain;
  }
  &-meters {
    background: url($imgMenuIconPath + 'meters.svg') no-repeat center/contain;
  }
  &-news {
    background: url($imgMenuIconPath + 'news.svg') no-repeat center/contain;
  }
  &-passport-office {
    background: url($imgMenuIconPath + 'passport-office.svg') no-repeat center/contain;
  }
  &-photos {
    background: url($imgMenuIconPath + 'photos.svg') no-repeat center/contain;
  }
  &-poll {
    background: url($imgMenuIconPath + 'poll.svg') no-repeat center/contain;
  }
  &-reports {
    background: url($imgMenuIconPath + 'reports.svg') no-repeat center/contain;
  }
  &-services {
    background: url($imgMenuIconPath + 'services.svg') no-repeat center/contain;
  }
  &-settings {
    background: url($imgMenuIconPath + 'settings.svg') no-repeat center/contain;
  }
  &-site-settings {
    background: url($imgMenuIconPath + 'site-settings.svg') no-repeat center/contain;
  }
  &-exit {
    background: url($imgMenuIconPath + 'exit.svg') no-repeat center/contain;
    &-invert {
      background-image: url($imgMenuIconPath + 'exit-invert.svg');
    }
  }
  &-client {
    background: url($imgPath + 'main.svg') no-repeat center/contain;
  }
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  &-label {
    margin-bottom: 0;
    line-height: 1;
    cursor: pointer;
    user-select: none;
  }
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &-mark {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      background: url($imgPath + 'checkbox.svg') no-repeat center/contain;
    }
    &::after {
      content: '';
      position: absolute;
      top: -10%;
      left: 10%;
      width: 100%;
      height: 100%;
    }
  }
  &-input:checked ~ &-mark::after {
    background: url($imgPath + 'check-solid.svg') no-repeat center/contain;
  }
  &-sm &-mark {
    width: 15px;
    height: 15px;
  }
  &-lg &-mark {
    width: 40px;
    height: 40px;
  }
}

.shadow {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15) !important;
}

.text-secondary {
  color: #5c5c5c !important;
}

.top-0 {
  top: 0;
}

.top-100 {
  top: 100%;
}

.right-0 {
  right: 0;
}

.left-100 {
  left: 100%;
}

.z-index-100 {
  z-index: 100;
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  &.fade:not(.show) {
    z-index: -1;
  }
}

.multiselect-container {
  width: 100%;
}

.dropdown-menu {
  border-top: 4px solid transparent;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  background: #182B53;
  color: #fff !important;
}

.form-group.inline {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.send-form {
  margin-right: 190px;
  &-wrap {
    border: 1px solid #C9C9C9;
    border-radius: 10px;
  }
}

.form-sender {
  position: relative;
  .tooltip-wrap,
  &::after {
    position: absolute;
    content: '';
    right: 10px;
    top: 50%;
    z-index: 1;
    width: 30px;
    height: 30px;
    margin-top: -15px;
  }
  &.success::after {
    background: url($imgPath + 'check-solid.svg') no-repeat center/contain;
  }
  &.error::after {
    background: url($imgPath + 'circle-xmark-solid.svg') no-repeat center/contain;
  }
  &.sending::after {
    background: url($imgPath + 'spinner-solid.svg') no-repeat center/contain;
    animation: loader 1.2s linear infinite;
  }
  .form-control {
    padding-right: 40px;
  }
  .tooltip-wrap {
    z-index: 2;
  }
}

.navbar {
  &-side {
    width: 180px;
  }
  &-menu {
    font-size: 18px;
  }
}

.side-menu {
  flex: 0 1 0;
}

.acabName {
  max-width: 360px;
}

.main-table {
  flex: 1 0 0;
  min-width: 0;
}

.search {
  position: relative;
  padding: 13px 8px;
  font-size: 14px;
  line-height: 16px;
  background: #FFFFFF;
  border: none;
  border-radius: 8px;
  &-wrap {
    box-shadow: 3px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  &-icon {
    background: url($imgPath + 'search.svg') no-repeat center/contain;
    width: 20px;
  }
}

.single-iban {
  .form-control {
    margin-right: 0.5rem;
  }
}

.result-block {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  &.fade:not(.show) {
    z-index: -1;
  }
}

.login-form {
  max-width: 454px;
}

.restore-form {
  max-width: 480px;
}

.footer {
  background-color: #182B53;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  h2, .h2, h3, .h3 {
    font-size: 1.25rem;
  }
  .text-xs-white {
    color: #FFFFFF !important;
  }
  .border-right {
    border: none !important;
  }
  .dropdown {
    &-close {
      position: absolute;
      color: #fff;
      right: 20px;
      top: 16px;
    }
    &-toggle {
      &::after {
        content: none;
      }
    }
    &-menu {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      border: none;
      border-radius: 0;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }
  .navbar {
    &-side {
      width: auto;
    }
    &-brand {
      margin-right: 0;
    }
  }
  .shadow {
    box-shadow: none !important;
  }
  .btn {
    font-size: 12px;
    &#{&}-auth {
      color: #fff !important;
      border: none !important;
      border-bottom: 1px solid currentColor !important;
    }
  }
  .userMenu {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    .close {
      position: absolute;
      right: 20px;
      top: 16px;
      z-index: 1050;
    }
  }
  .acabName {
    max-width: 59vw;
    width: 59vw;
  }
  .roleName {
    max-width: 41vw;
    width: 41vw;
  }
  .contacts {
    border: 1px solid #182B53;
    border-radius: 8px;
  }
  .footer {
    background-color: #FFFFFF;
    color: #2F2F2F;
    .w-25 {
      width: 100% !important;
    }
  }
  .user-change-form {
    border: none !important;
  }
}

// Loader styles
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  &::before,
  &::after {
    position: absolute;
    content: '';
  }
  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
  }
  &::after {
    z-index: 2;
    left: calc(50% - 32px);
    top: calc(50% - 32px);
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid;
    border-color: #000 transparent #000 transparent;
    animation: loader 1.2s linear infinite;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.inactive {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none;
}

.tab-active {
  font-size: 18px;
  font-weight: 500;
  color: #000 !important;
}

.remove-variant {
  top: 50%;
  margin-top: -10px;
  right: 10px;
}

.muted-block {
  opacity: .4;
  filter: grayscale(1);
  transition: all 0.2s ease;
}

.news {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.content, .public-ad {
  img {
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
}

.content {
  table {
    max-width: 100%;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader styles end